<template>
  <transition name="fade" mode="out-in">
    <div v-if="totalItems > 0" key="my-cart" class="microcart">
      <div class="microcart-products">
        <div class="microcart-products__wrapper">
          <transition-group name="fade" tag="div">
            <CartProduct
              v-for="product in productsInCart"
              :key="product.id"
              :product="product"
              :show-stock-errors="showStockErrors"
              :allow-edit="true"
              @click:remove="removeHandler(product)"
            />
          </transition-group>
          <div class="microcart-totals">
            <SfProperty
              v-for="total in totals"
              :key="total.code"
              :class="[
                'sf-property--full-width microcart__total-price',
                total.code,
              ]"
            >
              <template #name>
                <span :class="['sf-property__name']">{{ total.title }}</span>
              </template>
              <template #value>
                <SfPrice :regular="total.value | price" class="sf-price--big" />
              </template>
            </SfProperty>
          </div>
        </div>
      </div>
      <div class="microcart-summary">
        <div class="microcart-summary__wrapper">
          <div class="microcart-summary__actions">
            <SfButton
              v-if="store.orderConfig.orderEnabled"
              class="sf-button--full-width cart-action"
              :disabled="!isCartValid"
              @click.native="goToCheckout"
            >
              {{ $t("Checkout") }}
            </SfButton>
            <div
              v-if="
                store.orderConfig.enquiryEnabled &&
                store.orderConfig.orderEnabled
              "
              class="vertical-separator mobile-only"
            ></div>
            <SfButton
              v-if="store.orderConfig.enquiryEnabled"
              class="sf-button--full-width cart-action enquire mobile-only"
              :disabled="!isCartValid"
              @click.native="enquireProducts"
            >
              <font-awesome-icon
                :icon="['fab', 'whatsapp']"
                size="lg"
                class="pr-2"
                style="margin-right: 0.5rem"
              />
              {{ $t("Enquire") }}
            </SfButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else key="empty-cart" class="empty-cart">
      <div class="empty-cart__banner">
        <img src="@lib/icons/empty_cart.svg" alt="" class="empty-cart__icon" />
        <h3 class="empty-cart__label">
          {{ $t("Your bag is empty") }}
        </h3>
        <p class="empty-cart__description">
          {{
            $t(
              "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in."
            )
          }}
        </p>
      </div>
      <SfButton
        class="sf-button--full-width color-secondary cart-action"
        @click.native="startShopping"
      >
        {{ $t("Start shopping") }}
      </SfButton>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import onEscapePress from "@lib/mixins/onEscapePress";

import { SfButton, CartProduct, SfProperty, SfPrice } from "@lib/components";

import { notifications } from "@lib/modules/cart/helpers";

export default {
  components: {
    SfButton,
    CartProduct,
    SfProperty,
    SfPrice,
  },
  mixins: [onEscapePress],
  computed: {
    ...mapGetters({
      numberOfItems: "cart/getNumberOfItems",
      productsInCart: "cart/getCartItems",
      isCartValid: "cart/isCartValid",
      totals: "cart/getTotals",
      store: "store",
    }),
    totalItems() {
      return this.productsInCart.length;
    },
    productsCount() {
      let count = 0;
      this.productsInCart.forEach((product) => {
        count = count + parseInt(product.qty);
      });
      return count;
    },
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
  },
  methods: {
    closeMicrocartExtend() {
      this.$store.dispatch("ui/toggleMicrocart");
    },
    onEscapePress() {
      this.closeMicrocartExtend();
    },
    startShopping() {
      this.closeMicrocartExtend();
    },
    removeHandler(product) {
      this.$store.dispatch("cart/removeItem", { product: product });
    },
    goToCheckout() {
      this.$router.push({
        name: "checkout",
        params: { storeId: this.store.id },
      });
      this.closeMicrocartExtend();
    },
    changeQuantity(product, newQuantity) {
      this.$store.dispatch("cart/updateQuantity", {
        product: product,
        qty: newQuantity,
      });
    },
    enquireProducts() {
      let messages = [
        "Hi,",
        "I wanted to enquire about the following products from your online catalog",
        "",
      ];
      for (let product of this.productsInCart) {
        let itemRow = product.title + " ";
        if (itemRow.variantName) {
          itemRow += `- ${product.variantName}`;
        }
        itemRow = `${itemRow} x ${product.qty}`;
        messages.push(itemRow);
      }

      let messageString = `${encodeURIComponent(messages.join("\n"))}`;
      window.open(
        `https://api.whatsapp.com/send?phone=${this.store.contact.whatsappNumber}&text=${messageString}`,
        "_blank"
      );
    },
    onQuantityChange() {
      // Unfortunately $forceUpdate() is needed here because `totals` key in cart items
      // is added but not in a reactive way, so Vue is not able to detect this change and
      // re-render our view.
      // The callback for 'cart-after-itemchanged' event can be removed when the following PR
      // will be merged in VSF: https://github.com/DivanteLtd/vue-storefront/pull/4079/
      this.$forceUpdate();
    },
    showMaxStockNotification() {
      this.$store.dispatch(
        "notification/spawnNotification",
        notifications.createNotification({
          type: "danger",
          message: "Stock not available",
        })
      );
    },
  },
  beforeMount() {
    this.$bus.$on("cart-after-itemchanged", this.onQuantityChange);
  },
  beforeDestroy() {
    this.$bus.$off("cart-after-itemchanged", this.onQuantityChange);
  },
  created() {
    window.popStateDetected = false;
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      const IsItABackButton = window.popStateDetected;
      window.popStateDetected = false;
      if (IsItABackButton) {
        this.$store.dispatch("ui/toggleMicrocart");
        next(false);
        return "";
      } else {
        next();
      }
    });

    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
};
</script>

<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

.sf-sidebar {
  &__content {
    --sidebar-content-padding: 0 0 var(--spacer-sm) 0;

    @include for-desktop {
      --sidebar-content-padding: var(--spacer-sm) 0 var(--spacer-base) 0;
    }
  }

  &__circle-icon {
    z-index: 2;
  }
}

.microcart {
  box-sizing: border-box;
  padding: 0 var(--spacer-sm);
  @include for-desktop {
    padding: 0 var(--spacer-base);
  }
  background: #f8f8f8;
  flex: 1;
  display: flex;
  flex-direction: column;
  &__total-items {
    font-family: var(--font-family-secondary);
    font-size: var(--font-lg);
    font-weight: var(--font-light);
    margin: 0;
  }
  &__total-price {
    margin-bottom: calc(var(--spacer-xl) / 2);
  }
  overflow: hidden;

  &-title,
  &-products,
  &-summary {
    margin: 0;
    padding: 0;
  }

  &-title {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),
      0 3px 1px -2px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);

    &__wrapper {
      padding-bottom: 0.33rem;
    }
  }

  &-products {
    display: flex;
    flex: 1;
    overflow: hidden;
    &__wrapper {
      flex: 1;
      overflow: hidden;
      overflow: auto;
      padding-top: 0.75rem;
    }
  }

  &-summary {
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),
      0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.5);

    &__wrapper {
      padding-top: 0.75rem;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
    }

    .cart-action {
      padding: 0.75rem 2rem;
    }

    .cart-action.enquire {
      background: white;
      color: var(--c-primary);
      padding: 0.75rem;
    }
  }

  &-title,
  &-summary {
    margin: 0 calc(-1 * var(--spacer-sm));
    padding: 0 var(--spacer-sm);
    background: white;
    z-index: 1;

    @include for-desktop {
      margin: 0 calc(-1 * var(--spacer-base));
      padding: 0 var(--spacer-base);
    }
  }

  &-totals {
    padding: 1rem 0 0.5rem 0;
  }

  .collected-product {
    --collected-product-max-width: none;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #ccc;
    &__properties {
      margin-top: var(--spacer-xl);
      --property-name-font-size: 0.8rem;
      --property-value-font-font-size: 0.8rem;
    }
  }

  .cart-action {
    margin-bottom: var(--spacer-xs);
    margin-top: var(--spacer-xs);
    border-radius: 0.375rem;
  }

  .cart-summary {
    flex: 1 1 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }

  .microcart-totals {
    --price-font-weight: var(--font-normal);

    .grand_total {
      --property-name-font-size: var(--font-base);
      --property-name-font-weight: var(--font-bold);
      --property-name-font-color: var(--c-black);
      --price-font-size: var(--font-lg);
      --price-font-weight: var(--font-bold);
    }
  }
}

.empty-cart {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacer-sm);
  &__banner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    width: 18.125rem;
    height: 12.3125rem;
    margin-left: 60%;
    @include for-desktop {
      margin-left: 50%;
    }
  }
  &__label,
  &__description {
    line-height: 1.6;
    text-align: center;
  }
  &__label {
    margin-top: var(--spacer-2xl);
    font-size: var(--font-lg);
  }
  &__description {
    margin-top: var(--spacer-xl);
  }
}

@include for-desktop {
  .microcart {
    margin-top: 2rem;
  }
}
</style>
